<template>
<div id="main-wrapper">
    <!-- <Header/> -->  
    <div class="cookiesPolicyBox position-fixed bg-white round-sm shadow-lg p-4" v-if="cookie_div == true">
                        <h5 class="md-0">{{contentlist.cookies_settings_content1}} </h5>
                        <p>{{contentlist.cookies_settings_content2}}</p>
                        <div class="d-block">
                            <a href="javascript:void(0);" class="themeBtn medium me-2 me-sm-3" @click="cookieeSaveFunc('saveData')">{{contentlist.cookies_settings_content3}}</a>
                            <router-link :to="entityPath+'/cookies-policy'" class="borderBtn medium">{{contentlist.cookies_settings_content4}}</router-link>
                        </div>
                        
                    </div>

    <!-- <Container/> -->

    <section class="blockElement overflow-hidden">
        <div class="container storyImgCircle">
            <div class="row flex-column-reverse">
                <div class="col-12 col-md-7">
                    <div class="d-block text-center text-md-start">
                        <h1 class="pt-4 pt-md-0">{{contentlist.home_content1}}</h1>
                        <p>{{contentlist.home_content2}}</p>
                        <div class="d-block">
                            <router-link :to="entityPath+'/merchant-solution'" class="themeBtn medium me-2 me-sm-3">
                                {{contentlist.get_started_content}}
                            </router-link>
                            <router-link class="borderBtn medium" :to="{path: entityPath+'/contact-us',query:{page: 'home'}}">
                                {{contentlist.home_content4}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5">
                    <span class="storyImg"><img src="/assets/images/story-img.webp" alt="#" class="img-fluid" /></span>
                </div>
            </div>
            <div class="row" v-if="eventArray.length > 0">
                <div class="col-12 col-lg-7">
                    <div class="mt-5">
<!-- event_type -->
                        <h3 class="mb-3">{{contentlist.home_content5}}</h3>  
                        <Carousel class="meetUsCarousel"> 
                            <Slide v-for="list in eventArray" :key="list" >
                                <div class="carousel__item">
                                    <div class="meetUs">
                                        <div class="d-flex">
                                            <div class="me-3 mt-2 w50">
                                                <img src="/assets/images/meet-us.webp" alt="meet us" class="img-fluid" />
                                            </div>
                                            <div class="me-3">
                                                <div class="d-flex meetUsRow">
                                                    <h5 class="mb-0 text-start me-0 me-md-3">{{list.title}}</h5>
                                                    <span class="bodyfontcolor semibold f-14 rounded-pill borderbg meetDate lh-base">
                                                    {{list.date}}
                                                    </span>
                                                </div>
                                                <p class="f-14 fst-italic primarycolor semibold mb-0">
                                                    <a :href="list.link" target="_blank" class="d-flex align-items-center text-decoration-none"><span>{{contentlist.explore_more_content}}</span>
                                                    <vue-feather class="ms-2 semibold" type="arrow-right" size="16"></vue-feather></a>
                                                </p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <template #addons v-if="eventArray.length > 1">
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                </div>
            </div>
            
        </div>
        
    </section>
    
    <section class="blockElement smartChoice">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 text-center text-md-start mb-2 mb-md-0">
                    <h2 class="mb-0 d-inline">
                        {{contentlist.home_content7}}<br />
                        {{contentlist.home_content8}}
                    </h2>
                    <h2 class="typed-text primarycolor bg-transparent d-inline"></h2>
                    <h2 class="cursor bg-transparent d-inline">&nbsp;</h2>
                    <h2 class="d-inline">Company</h2>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <span class="mt-4 pb-4 mt-md-0 pb-md-0 m-auto m-auto m-md-0 verticalSeprator">&nbsp;</span>
                    <p class="d-block ms-0 ms-md-5 mb-0 text-center text-md-start">{{contentlist.home_content9}}</p>
                </div>
            </div>

            <Carousel class="smartChoiceCarousel borderedT mt-5" :slidesCount="3" :settings="smartChoiceCarousel" :breakpoints="breakpoints" :mouseDrag="false">
                <Slide :key="slide">
                    <div class="carousel__item">
                        <div class="smartChoiceBox pt-5 text-center text-md-start">
                            <div class="me-3 smartChoiceBoxIcon lightgreybg bordered-pill d-flex align-items-center justify-content-center">
                                <img src="/assets/images/global-reach.webp" alt="Global Reach and Sales Increase" class="img-fluid" />
                            </div>
                            <h5 class="mt-3">{{contentlist.home_content10}}</h5>
                            <p class="mb-0">{{contentlist.home_content11}}</p>
                        </div>
                    </div>
                    
                </Slide>
                <Slide :key="slide">
                    <div class="carousel__item">
                        <div class="smartChoiceBox pt-5 text-center text-md-start">
                           <div class="me-3 smartChoiceBoxIcon lightgreybg bordered-pill d-flex align-items-center justify-content-center">
                              <img src="/assets/images/unmatched-speed.webp" alt="Unmatched Speed and Security" class="img-fluid" />
                           </div>
                           <h5 class="mt-3">{{contentlist.home_content12}}</h5>
                           <p class="mb-0">{{contentlist.home_content13}}
                              
                           </p>
                        </div>
                    </div>
                    
                
                </Slide>
                <Slide :key="slide">
                    <div class="carousel__item">
                     <div class="smartChoiceBox pt-5 text-center text-md-start">
                        <div class="me-3 smartChoiceBoxIcon lightgreybg bordered-pill d-flex align-items-center justify-content-center">
                            <img src="/assets/images/supreme-ux.webp" alt="Supreme User Experience" class="img-fluid" />
                        </div>
                        <h5 class="mt-3">{{contentlist.home_content14}}</h5>
                        <p class="mb-0">{{contentlist.home_content15}}
                            
                        </p>
                     </div>
                    </div>
                    
                
                </Slide>
                <Slide :key="slide">
                    <div class="carousel__item">
                        <div class="smartChoiceBox pt-5 text-center text-md-start">
                            <div class="me-3 smartChoiceBoxIcon lightgreybg bordered-pill d-flex align-items-center justify-content-center">
                                <img src="/assets/images/support.webp" alt="Stellar Support" class="img-fluid" />
                            </div>
                            <h5 class="mt-3">{{contentlist.home_content16}}</h5>
                            <p class="mb-0">{{contentlist.home_content17}}</p>
                        </div>
                    </div>
                </Slide>
                <Slide :key="slide">
                    <div class="carousel__item">
                        <div class="smartChoiceBox pt-5 text-center text-md-start">
                           <div class="me-3 smartChoiceBoxIcon lightgreybg bordered-pill d-flex align-items-center justify-content-center">
                              <img src="/assets/images/model-flexible.webp" alt="Ultra-Low Cost and Efficiency" class="img-fluid" />
                           </div>
                           <h5 class="mt-3">{{contentlist.home_content18}}</h5>
                           <p class="mb-0">{{contentlist.home_content19}}
                              
                           </p>
                        </div>
                    </div>
                </Slide>


                <Slide :key="slide">
                    
                    
                    <div class="carousel__item">
                     <div class="smartChoiceBox pt-5 text-center text-md-start">
                        <div class="me-3 smartChoiceBoxIcon lightgreybg bordered-pill d-flex align-items-center justify-content-center">
                            <img src="/assets/images/compliance-and-regulation.webp" alt="Compliance and Regulation" class="img-fluid" />
                        </div>
                        <h5 class="mt-3">{{contentlist.home_content20}}</h5>
                        <p class="m-0">{{contentlist.home_content21}}
                           
                        </p>
                     </div>
                    </div>
                </Slide>
                
                <template #addons="{ slidesCount }">
                    <Navigation />
                    <Pagination v-if="slidesCount > 3" />
                </template>
            </Carousel>

            
    
            <div class="d-flex justify-content-center mt-4">
                <router-link :to="entityPath+'/merchant-solution'" class="themeBtn medium">{{contentlist.home_content22}}</router-link>
                
            </div>
        </div>
    </section>

    <section class="blockElement d-flex align-items-center lightgreybg">
        <div class="container">
            <div class="row d-flex align-items-center flex-row-reverse">
                <div class="col-md-6">
                    <div class="ms-0 ms-md-5">
                        <img src="/assets/images/convert-crypto-instant.webp" alt="Story Image" class="img-fluid" />
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-start">
                    <h2 class="me-4 mb-0">{{contentlist.home_content23}}</h2>
                    
                    <!-- APEC Start -->
                    <p class="d-block mt-3 mt-md-4 mb-0" v-if="entityName == 'apec'">
                            {{contentlist.home_apec_content24}}
                        </p>
                    <!-- APEC End -->
                    <!-- Global Start -->
                    <p class="d-block mt-3 mt-md-4 mb-0" v-else>
                        {{contentlist.home_global_content24}}
                    </p>
                    <!-- Global End -->
                    <router-link :to="entityPath+'/crypto-conversion'" class="themeBtn medium mt-4">
                        {{contentlist.read_more_content}}
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement d-flex align-items-center takeControlSection">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-md-6">
                    
                    <img src="/assets/images/take-control-apec.webp" alt="Story Image" class="img-fluid" v-if="entityName == 'apec'"  />
                    <img src="/assets/images/take-control-global.webp" alt="Story Image" class="img-fluid" v-else />
                </div>
                <div class="col-md-6 text-center text-md-start">
                    <div class="ms-0 ps-0 ms-md-5 ps-md-3">
                        <h2 class="me-4 mb-0">{{contentlist.home_content26}}</h2>

                        <p class="d-block mt-3 mt-md-4 mb-0">{{contentlist.home_content27}}</p>
                        <router-link :to="entityPath+'/payout'" class="themeBtn medium mt-4">
                            {{contentlist.view_more_content}}
                        </router-link>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement d-flex align-items-center popularCryptoSection">
        <div class="container popularCryptoImgSection">
            <div class="row flex-column-reverse">
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="pt-4 pt-md-0 me-0 me-md-4 mb-0">{{contentlist.home_content28}}</h2>

                    <p class="d-block mt-3 mt-md-4 mb-0">{{contentlist.home_content29}}</p>
                    <router-link :to="entityPath+'/supported-coin'" class="themeBtn medium mt-4">
                        {{contentlist.view_more_content}}
                    </router-link>
                    
                </div>

                <div class="col-12 col-md-6">
                    <span class="popularCryptoImg">
                    <img src="/assets/images/popular-crypto-desktop.webp" alt="Story Image" class="img-fluid popularCryptoDesktop" />
                    <img src="/assets/images/popular-crypto-mobile.webp" alt="Story Image" class="img-fluid popularCryptoMobile" />
                </span>
                </div>
            </div>
        </div>
    </section>
    <!-- <Footer /> -->
    <section class="blockElement primarybg mt-0 mt-lg-5" data-aos="fade-up" data-aos-duration="1800">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center primarybgSection">
                    <div class="criptoIcon">
                        <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid" /></span>
                        <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid" /></span>
                        <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid" /></span>
                        <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid" /></span>
                    </div>
                    <div class="content position-relative">
                        <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                        <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                        <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
     
</div>

</template>

<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   import "vue3-carousel/dist/carousel.css";
    import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";
    import eventArrayFile from '../../../../public/assets/js/event.js'

    import { useCookies } from "vue3-cookies";
   export default ({
    mixins: [ commonAllmixins],   //anamica
      data() {
         return {
            eventArray :  [],
     smartChoiceCarousel: {
        snapAlign: "end",
        breakpoints: {
        320: {
          itemsToShow: 1,
          snapAlign: "end",
          itemsToScroll: 1,
        },
        768: {
          itemsToShow: 2,
          snapAlign: "end",
          itemsToScroll: 2,
        },
        992: {
          itemsToShow: 3,
          snapAlign: "end",
          itemsToScroll: 3,
        },
        
      },
      },
        cookie_div : true
         };

         
      },
      setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
      created(){
        // console.log('home=1=> ',this.$root.entityName)
         this.eventArray = eventArrayFile
         this.cookieeSaveFunc('readData')
      },
      methods:{
        cookieeSaveFunc(getData){
            if(getData == 'saveData'){
                this.cookies.set("capitalWalletEntity",this.entityName);
            }

            if(this.cookies.get("capitalWalletEntity")){
                this.cookie_div = false
            }else{
               this.cookie_div = true 
            }
        }
      },
      components: {Carousel, Slide, Pagination, Navigation },
       mounted: function() {
        let arraydata = ['Real-Estate','E-Commerce','Marketplaces','Telecoms','Hospitality','SaaS Providers','Consulting','Marketing Agencies','Translators'];
        this.typeTextFunc(arraydata)
      }
   });
</script>

